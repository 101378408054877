import { I18N } from '@/enums/i18n.enum';
import { theme } from '@/themes/MolunderTheme';
import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from '@/dto/notifications/Notification.dto';
import { NOTIFICATION_TYPES } from '@/enums/notification.enum';
import { formatDate, DATE_FORMAT } from '@utils/date.utils';
import { observer } from 'mobx-react';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

interface IComponentProps {
  notification: Notification;
  active: boolean;
  handleClick: (notification: Notification) => void;
}

const getNotificationMessage = (
  type: NOTIFICATION_TYPES,
  messageInfo: string
) => {
  const { t } = useTranslation();
  const parsedMessage = JSON.parse(messageInfo);
  const isWeb = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    NOTIFICATION_NEW_DECLARATION_MESSAGE,
    NOTIFICATION_NEW_OFFER,
    NOTIFICATION_NEW_RESERVATION,
    NOTIFICATION_MASS_COMPLETED,
    NOTIFICATION_MASS_EXPIRED,
    NOTIFICATION_EXPIRATION_DATE,
    COMMONS_TONNE,
  } = I18N;
  let messageComponent = null;

  switch (type) {
    case NOTIFICATION_TYPES.MASS_REQUEST:
      messageComponent = (
        <>
          <Typography variant='subtitle1'>{`${t(NOTIFICATION_NEW_OFFER)} - ${
            parsedMessage.name
          }`}</Typography>
          <Typography
            variant='subtitle2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${parsedMessage.volume} ${t(COMMONS_TONNE)}`}
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`Supplier: ${parsedMessage.customer}`}
          </Typography>
        </>
      );
      break;
    case NOTIFICATION_TYPES.MASS_SUPPLY:
      messageComponent = (
        <>
          <Typography variant='subtitle1'>{`${t(
            NOTIFICATION_NEW_RESERVATION
          )}- ${parsedMessage.name}`}</Typography>
          <Typography
            variant='subtitle2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${parsedMessage.volume} ${t(COMMONS_TONNE)}`}
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`Requestor: ${parsedMessage.customer}`}
          </Typography>
        </>
      );
      break;
    case NOTIFICATION_TYPES.MASS_COMPLETED:
      messageComponent = (
        <>
          <Typography variant='subtitle1'>{`${t(
            NOTIFICATION_MASS_COMPLETED
          )} - ${parsedMessage.name}`}</Typography>
        </>
      );
      break;
    case NOTIFICATION_TYPES.MASS_EXPIRED:
      messageComponent = (
        <>
          <Typography variant='subtitle1'>{`${t(NOTIFICATION_MASS_EXPIRED)} - ${
            parsedMessage.name
          }`}</Typography>
          <Typography
            variant='subtitle2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${t(NOTIFICATION_EXPIRATION_DATE)}: ${formatDate(
              parsedMessage.requestEndPeriod,
              DATE_FORMAT.DDMMYYYY
            )}`}
          </Typography>
        </>
      );
      break;
    case NOTIFICATION_TYPES.NEW_DECLARATION_MESSAGE:
      messageComponent = (
        <>
          <Typography variant='subtitle1'>{`${t(
            NOTIFICATION_NEW_DECLARATION_MESSAGE
          )}`}</Typography>
          <Typography
            variant='subtitle2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${parsedMessage?.senderName} has sent you a new message ${parsedMessage?.lastMessageSent}`}
          </Typography>
        </>
      );
      break;
  }

  return isWeb ? (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='stretch'
      spacing={1}
    >
      {messageComponent}
    </Stack>
  ) : (
    <>{messageComponent}</>
  );
};

const NotificationCard = (props: IComponentProps) => {
  const isWeb = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const { notification, active, handleClick } = props;

  const message = getNotificationMessage(
    notification.type,
    notification.information
  );

  return (
    <>
      <Card
        sx={{
          mb: 1,
          bgcolor: active ? 'primary.light' : 'none',
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.12), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.1)',
          '&:hover': { bgcolor: 'action.hover', cursor: 'pointer' },
        }}
        onClick={() => handleClick(notification)}
      >
        <CardContent
          sx={{
            paddingX: isWeb ? '1.5rem' : '1rem',
            paddingTop: isWeb ? '1.375rem' : '1.5rem',
            paddingBottom: isWeb ? '1.375rem' : '1rem',
          }}
        >
          <Grid container spacing={isWeb ? 2 : 0}>
            {active && (
              <Grid
                sx={{
                  paddingTop: '12px',
                  paddingRight: !isWeb ? '10px' : '0px',
                }}
              >
                <CircleRoundedIcon
                  sx={{
                    fontSize: '10px',
                    color: 'primary.main',
                  }}
                />
              </Grid>
            )}
            <Grid item>
              <Stack
                spacing={0.5}
                justifyContent='space-between'
                sx={{ height: '100%' }}
              >
                {message}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default observer(NotificationCard);
