import http from '@/apis';
import { CreateUpdateNotification } from '@/dto/notifications/CreateUpdateNotification.dto';
import { Notification } from '@/dto/notifications/Notification.dto';
import { NotificationPaginationRequest } from '@/dto/notifications/NotificationPaginationRequest.dto';
import { prepareGetQuery } from '@utils/api.utils';

class NotificationService {
  prefix = 'notifications';
  ssePrefix = this.prefix + '/sse';

  public listenNotification(userId: number) {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_BACKEND_URL}/${this.ssePrefix}/${userId}`
    );
    return eventSource;
  }

  public async getNotifications(
    query?: NotificationPaginationRequest
  ): Promise<Notification[]> {
    let url = this.prefix;
    if (query) {
      url += prepareGetQuery(query as Record<string, unknown>);
    }
    const result = await http.get(url);
    return result.data.result.data;
  }

  public async updateNotification(
    model: CreateUpdateNotification,
    notificationId: number
  ) {
    const result = await http.put(`${this.prefix}/${notificationId}`, model);
    return result.data.result;
  }

  public async clearNotifications() {
    const result = await http.get(`${this.prefix}/clear`);
    return result.data.result;
  }
}

export default new NotificationService();
