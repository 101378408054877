import notificationService from '@/apis/notification.service';
import { clone } from '@utils/object.utils';
import { makeObservable, observable, action, computed } from 'mobx';
import { Notification } from '@/dto/notifications/Notification.dto';
import { NotificationPaginationRequest } from '@/dto/notifications/NotificationPaginationRequest.dto';
import { CreateUpdateNotification } from '@/dto/notifications/CreateUpdateNotification.dto';
import { resetTimeZeroOfDate } from '@utils/date.utils';

class NotificationStore {
  listening: boolean;
  notifications: Notification[];

  constructor() {
    this.listening = false;
    this.notifications = [];
    makeObservable(this, {
      listening: observable,
      notifications: observable,
      listenNotification: action,
      getNotifications: action,
      updateNotification: action,
      clearNotifications: action,
      notificationGroupByDate: computed,
      unreadNotificationCount: computed,
    });
  }

  get notificationGroupByDate(): Map<number, Notification[]> {
    const map = new Map<number, Notification[]>();
    this.notifications.map((n: Notification) => {
      const key = resetTimeZeroOfDate(n.createdAt).getTime();
      const olds = map.get(key);
      if (olds) {
        map.set(key, [...olds, n]);
      } else {
        map.set(key, [n]);
      }
      return n;
    });
    return map;
  }

  get unreadNotificationCount(): number {
    let count = 0;
    this.notifications.map((n: Notification) => {
      if (!n.readTime) count++;
    });
    return count;
  }

  listenNotification(userId: number) {
    const eventSource = notificationService.listenNotification(userId);
    eventSource.onmessage = function logEvents(event) {
      this.notifications = [
        ...this.notifications,
        JSON.parse(clone(event.data)),
      ];
    }.bind(this);
    this.listening = true;
  }

  async getNotifications(query?: NotificationPaginationRequest) {
    const data = await notificationService.getNotifications(query);
    this.notifications = data;
  }

  async updateNotification(
    model: CreateUpdateNotification,
    notificationId: number
  ) {
    const success = await notificationService.updateNotification(
      model,
      notificationId
    );

    if (success) {
      const notifications = [...this.notifications];
      const index = this.notifications.findIndex((n) => n.id == notificationId);
      notifications[index] = { ...notifications[index], ...model };
      this.notifications = notifications;
    }
  }

  async clearNotifications() {
    const success = await notificationService.clearNotifications();
    if (success) {
      const currentTime = new Date();
      this.notifications = this.notifications.map((n) => {
        return { ...n, readTime: currentTime };
      });
    }
  }

  setNotificationsInit() {
    this.notifications = [];
  }
}

export default NotificationStore;
